/* Styling for the search bar container */
.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Style for the search input */
  .search-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  .addAccount {
    margin-left: 33%;
    margin-top: 15%;
    width: 30%;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  /* Style for the select dropdown */
  .search-select {
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
  
  /* Style for the search button */
  .search-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  /* Style for the search button */
  .search-button1 {
    padding: 10px 15px;
    background-color: #fc7304;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  /* Style for the search button */
  .search-button2 {
    padding: 10px 15px;
    background-color: #09d769;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  /* Hover effect for the search button */
  .search-button:hover {
    background-color: #0056b3;
  }
  