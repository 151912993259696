/* BalanceSheet.css */

.balance-sheet-container {
    margin: 20px;
    margin-top: 50px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .balance-section {
    display: flex;
    justify-content: space-between;
  }
  
  .label-section {
    flex: 1;
    margin-right: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
 
  .input-group {
    margin-right: 10px; /* Adjust the spacing between the input fields */
  }
  /* Additional styling for better visibility */
  body {
    font-family: 'Arial', sans-serif;
  }
  
  .balance-section::after {
    content: '';
    display: table;
    clear: both;
  }
  