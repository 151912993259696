/* Add styles as needed */
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    
  }
  tr {
    color: #000;
  }
  th {
    background-color: #f2f2f2;
  }
  .checkbox-label {
    margin-right: 15px; /* Add margin-right for spacing between checkboxes */
  }
